


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import ComplianceList from "@/views/compliance/ComplianceList.vue";
import _ from "lodash";

@Component({
  components: {ComplianceList}
})
export default class CompliancePackageComponent extends Vue {
  public userId = this.$brokerId;
  public previousInProcess: boolean = false;
  public inProcess: boolean = false;
  public complianceList: any[] = [];
  public loanTxnId: any = '';
  public borrowerName: string = '';
  public sourceType: string = 'broker';

  public async getComplianceList() {
    this.$store.state.wemloLoader = true;
    let response = await Axios.post(
      BASE_API_URL + "onBoarding/getComplianceList",
      {
        loanTxnId: this.$route.query.id
      });
    this.$store.state.wemloLoader = false;
    this.complianceList = response.data || [];
  }

  async saveBorrowerCompliance(selectedPackage){
    let $package = this.$refs['complianceList'] as any;
    this.$store.state.wemloLoader = true;
    let response = await Axios.post(
      BASE_API_URL + "compliance/saveBorrowerCompliancePackage",
      {
        loanTxnId: this.$route.query.id,
        compliancePackage : selectedPackage
      });
    if(response.status == 200){
      this.$snotify.success("Compliance Package Updated.");
    }else{
      this.$snotify.error("Error Updating Compliance Package.");
    }
    this.$store.state.wemloLoader = false;
  }

  public async getBorrowerInfo() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "borrower/getBorrowerInfo",
        {
          loanTxnId: this.loanTxnId,
          userId: this.$userId
        });
      if(response.status == 200){
        const {firstName, middleName, lastName} = _.get(response.data, 'borrowerInfo[0].personalInfo.name') || {};
        this.borrowerName = `${firstName} ${middleName} ${lastName}`;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id;
    
    if (this.$userType === "Wemlo Processor") {
      this.sourceType = "processor";
    }

    this.getComplianceList();
    this.getBorrowerInfo();
  }
}
