


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import ComplianceList from "@/views/compliance/ComplianceList.vue";

@Component({
  components: {ComplianceList}
})
export default class EditCompliancePackageComponent extends Vue {
  public complianceList: any[] = [];

  public async getBrokerDefaultCompliance() {
    try {
      this.$store.state.wemloLoader = true;

      const {data = []} = await Axios.post(BASE_API_URL + "onBoarding/getBrokerDefaultCompliance", {});

      this.complianceList = data;
    } catch(error) {
      console.log(error);
      this.$snotify.error("Failed to get Compliance Package");
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  async saveBrokerDefaultCompliance() {
    try {
      this.$store.state.wemloLoader = true;

      const complianceList = this.$refs.complianceList['complianceList'];
      const selectedComplianceIds = this.$refs.complianceList['selectedComplianceIds'];
      
      const selectedComplianceList = complianceList.map(c => ({
        categoryName: c.categoryName,
        selected: selectedComplianceIds.includes(c.categoryName),
      }));
      
      await Axios.post(BASE_API_URL + "onboarding/saveBrokerDefaultCompliance", {
        complianceList: selectedComplianceList,
      });

      this.$snotify.success("Compliance Package Updated.");
    } catch (error) {
      console.log(error);
      this.$snotify.error("Failed to update Compliance Package");
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  async mounted() {
    await this.getBrokerDefaultCompliance();
  }
}
