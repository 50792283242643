



import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import Datepicker from "vuejs-datepicker";
import { US_STATES } from '@/constants';

@Component({
  components: {Datepicker}
})
export default class ComplianceComponent extends Vue {
  public borrowerInfo = [];

  public search = null;
  public year = new Date().getFullYear();
  public page = 1;
  public loading = false;
  public limit = 10;
  public receiveResponse = false;

  public mountCall = true;
  public yearChangeCall = false;
  public mortgageCallReports = [];
  public currentComplianceTab :String = 'Active'; 
  public fetchComplianceLoader :Boolean = null;
  public searchComplianceString :String = "";
  public mortgageReportYear: String = null;
  public mortgageReportQuarter: String = null;
  public mortgageReportState: String = null;
  public states: Array<Object> = US_STATES;
  public selectedReport: Object = null;
  public selectedType: String = null;
  public currentMCRSortField: string = 'updatedAt';
  public currentMCRSortDir: number = 1;

  async infiniteHandler($state) {
    let bottomOfWindow =
      document.documentElement.scrollTop + window.innerHeight ===
      document.documentElement.offsetHeight;
    if (bottomOfWindow) {
      this.mountCall = false;
      this.yearChangeCall = false;
      this.page = this.page + 1;
      await this.getBorrowerListForCompliance();
      if (this.borrowerInfo.length > 0 && this.receiveResponse) {
        setTimeout(async () => {
          $state.loaded();
        }, 2000);
      } else {
        $state.complete();
      }
    }
  }

  public sort(field) {
    if (field === this.currentMCRSortField) {
      this.currentMCRSortDir = this.currentMCRSortDir === 1 ? -1 : 1;
    } else {
      this.currentMCRSortDir = 1;
    }
    
    this.currentMCRSortField = field;
    
    this.mortgageCallReports = this.mortgageCallReports.sort((a: Object, b: Object) => {
      if (a[this.currentMCRSortField] < b[this.currentMCRSortField]) {
        return -1 * this.currentMCRSortDir;
      }
      if (a[this.currentMCRSortField] > b[this.currentMCRSortField]) {
        return 1 * this.currentMCRSortDir;
      }
      return 0;
    })
  }

  public sortIconClass(field) {
    if (field === this.currentMCRSortField) {
      return this.currentMCRSortDir === -1 ? 'icon-wemlo12' : 'icon-wemlo13';
    }
  }

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  searchYear(e) {
    let {value} = e.target;
    this.year = value;
    this.mountCall = false;
    this.page = this.year == 1 ? 1 : 0;
    this.getBorrowerListForCompliance();
  }

  public async fetchMortgageCallReports() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "compliance/fetchMortgageCallReports",
        {
          params: {
            brokerId: this.$brokerId
          }
        }
      )

      this.mortgageCallReports = response.data.mortgageCallReportInfo;
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchMortgageCallReport(report, type) {
    try {
      let response = await Axios.get(
        BASE_API_URL + "compliance/fetchMortgageCallReport",
        {
          params: {
            brokerId: this.$brokerId,
            year: report.year,
            quarter: report.quarter,
            state: report.state,
            from: report.dateFrom,
            to: report.dateTo,
          }
        }
      )

      const file = new Blob (
        [type === 'xml' ? response.data.xml : new Uint8Array(response.data.pdf.data)],
        {type: type === 'xml' ? 'text/plain': 'application/pdf'}
      );

      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a')

      link.href = fileURL
      link.setAttribute('download', `report-${report.year}-Q${report.quarter}-${report.state}.${type}`);

      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.log(error);
    }
  }

  public async getBorrowerListForCompliance() {
    try {
    this.$store.state.wemloLoader = true;
      this.loading = this.mountCall ? true : false;
      let response = await Axios.get(
        BASE_API_URL + "compliance/getBorrowerListForCompliance",
        {
          params: {
            brokerId: this.$brokerId,
            userId: this.$userId,
            type: this.userType,
            year: this.year,
            filterBy: this.currentComplianceTab,
            options: {
              page: this.page,
              limit: this.limit
            }
          }
        }
      );
      this.borrowerInfo = [];
      this.loading = false;
      this.receiveResponse =
        response.data.borrowerInfo.length > 0 ? true : false;
      if (this.yearChangeCall) this.borrowerInfo = response.data.borrowerInfo;
      else this.borrowerInfo.push(...response.data.borrowerInfo);
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async generateReport() {
    try {
      let formValid = await this.$validator.validateAll();

      if (!formValid) {
        return;
      }

      this.$store.state.wemloLoader = true;
      this.loading = true;

      let response = await Axios.get(
        BASE_API_URL + "compliance/fetchMortgageCallReport",
        {
          params: {
            year: this.mortgageReportYear,
            quarter: this.mortgageReportQuarter,
            state: this.mortgageReportState,
          },
        }
      );
      
      await this.fetchMortgageCallReports()
    } catch (error) {
      console.log(error);
    }

    this.loading = false;
    this.$store.state.wemloLoader = false;
  }

 public async fetchCompliance(category){
    try {
      if(category != this.currentComplianceTab){
        this.currentComplianceTab = category;
        this.fetchComplianceLoader = true;
        if(category != 'Mortage Call Reports'){
          this.year = new Date().getFullYear();
          this.getBorrowerListForCompliance();
        }
        this.fetchComplianceLoader = false;
      }
    }  
    catch (error) {
      this.fetchComplianceLoader = false;
      console.log(error);
    }
  }

  get filteredList() {
    const searchComplianceStringLowerCase = this.searchComplianceString.toLowerCase();

    return this.borrowerInfo.filter(borrowerInfo => {
      return (
        borrowerInfo.loanTxnId.toLowerCase().includes(searchComplianceStringLowerCase) ||
        borrowerInfo.loanStatus.toLowerCase().includes(searchComplianceStringLowerCase) ||
        borrowerInfo.primaryBorrower.firstName.toLowerCase().includes(searchComplianceStringLowerCase) ||
        borrowerInfo.primaryBorrower.lastName.toLowerCase().includes(searchComplianceStringLowerCase) ||
        borrowerInfo.loanInformation.lenderName.toLowerCase().includes(searchComplianceStringLowerCase)
      );
    });
  }

  public years(start: number, end: number) {
    let years = [];
    for (let i = end; i > start; i--) {
      years.push(i);
    }
    return years;
  }

  public openPreviewModal(report, type) {
    this.$modal.show('preview')
    this.selectedReport = report
    this.selectedType = type
  }

  public downloadSelectedReport() {
    this.$modal.hide('preview')
    this.fetchMortgageCallReport(this.selectedReport, this.selectedType)
  }

  mounted() {
    this.getBorrowerListForCompliance();
    this.fetchMortgageCallReports();
  }
}
