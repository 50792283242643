



import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import Datepicker from "vuejs-datepicker";

@Component({
  components: {Datepicker}
})
export default class WemloProcessorComplianceComponent extends Vue {
  public borrowerInfo = [];

  public search: string = null;
  public year: number = new Date().getFullYear();
  public page: number = 1;
  public loading: boolean = false;
  public limit: number = 10;

  public mountCall: boolean = true;
  public yearChangeCall: boolean = false;
  public searchComplianceString: string = "";

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  searchYear(e) {
    let {value} = e.target;
    this.year = value;
    this.mountCall = false;
    this.page = this.year == 1 ? 1 : 0;
    this.getBorrowerListForCompliance();
  }

  public async getBorrowerListForCompliance() {
    try {
      this.$store.state.wemloLoader = true;
      this.loading = this.mountCall ? true : false;
      let response = await Axios.get(
        BASE_API_URL + "compliance/getBorrowerListForCompliance",
        {
          params: {
            brokerId: this.$brokerId,
            userId: this.$userId,
            type: this.userType,
            year: this.year,
            filterBy: "Closed",
            options: {
              page: this.page,
              limit: this.limit
            }
          }
        }
      );
      this.borrowerInfo = [];
      this.loading = false;
      if (this.yearChangeCall) {
        this.borrowerInfo = response.data.borrowerInfo;
      } else {
        this.borrowerInfo.push(...response.data.borrowerInfo);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  get filteredList(){
    return this.borrowerInfo.filter(name => {
      return (
        name.primaryBorrower.firstName
          .toLowerCase()
          .includes(this.searchComplianceString.toLowerCase()) ||
        name.primaryBorrower.lastName
          .toLowerCase()
          .includes(this.searchComplianceString.toLowerCase()) || 
        name.loanInformation.lenderName
          .toLowerCase()
          .includes(this.searchComplianceString.toLowerCase())
      );
    });
  }

  public years(start: number, end: number) {
    let years = [];
    for (let i = end; i > start; i--) {
      years.push(i);
    }
    return years;
  }

  mounted() {
    this.getBorrowerListForCompliance();
  }
}
